import classnames from "classnames/bind";
import styles from './TopMenu.module.scss';
import {
    BellOutlined,
    GoldOutlined, LoginOutlined,
    MoonOutlined, SecurityScanOutlined,
    SunOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Avatar, Badge, Dropdown} from "antd";
import {useContext, useEffect, useState} from "react";
import ItemDropDown from "./ItemDropDown/ItemDropDown";
import {Link, useActionData, useNavigate} from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import {redirectAuth} from "../../routes/web";
import {numberFormat} from "../../helpers/helpers";
import logout from "../../api/auth/logout";
import LoadingContext from "../../contexts/LoadingContext";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const cx = classnames.bind(styles);

function TopMenu({balance}) {
    const [dark,setDark] = useState(false);
    const signOut   = useSignOut();
    const accessToken = useAuthHeader();
    const navigate  = useNavigate();
    const {setLoading} = useContext(LoadingContext);
    const handleMode = () => {
        setDark(!dark);
    }

    const handleSignOut = async () => {
        setLoading(true);
        const response = await logout(accessToken);
        if (response.success) {
            signOut();
            navigate(redirectAuth)
        }
        setLoading(false);
    }

    const items = [
        {
            key: 'balance',
            label: (
                <ItemDropDown icon={<GoldOutlined/>}>{numberFormat(balance)} VNĐ</ItemDropDown>
            )
        },
        {
            type: 'divider'
        },
        {
            key: 'profile',
            label: (
                <ItemDropDown icon={<UserOutlined/>} to={'/user/profile'}>Trang cá nhân</ItemDropDown>
            ),
        },
        {
            key: 'security',
            label: (
                <ItemDropDown icon={<SecurityScanOutlined/>} to={'/user/security'}>Bảo mật</ItemDropDown>
            ),
        },
        {
            key: 'logout',
            label: (
                <ItemDropDown onClick={handleSignOut} icon={<LoginOutlined/>}>Đăng xuất</ItemDropDown>
            )
        }
    ];
    return (
        <div
            className={cx('wrapper')}
        >
            <div
                className={cx('icon')}
                onClick={handleMode}
            >
                {dark ? <MoonOutlined/> : <SunOutlined/>}
            </div>
            <div
                className={cx('icon')}
                style={{
                    position: 'relative'
                }}
            >
                <BellOutlined/>
                <span className={cx('count')}>5</span>
            </div>
            <div
                className={cx('icon')}
            >
                <Dropdown
                    placement={'bottomRight'}
                    arrow={{
                        pointAtCenter: true,
                    }}
                    menu={{
                        items
                    }}
                >
                    <Avatar size={'default'} icon={<UserOutlined />} />
                </Dropdown>
            </div>
        </div>
    )
}

export default TopMenu;