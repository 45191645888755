import {Button, Flex, Modal, Space, Table, Tag, Typography} from "antd";
import TextCopy from "../../TextCopy";
import {convertUnixTimestampToDateTime, numberFormat} from "../../../helpers/helpers";
import Search from "antd/es/input/Search";
import {useEffect, useState} from "react";
import getTransaction from "../../../api/bank-transaction/getTransaction";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { DatePicker } from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import searchTransactions from "../../../api/bank-transaction/searchTransactions";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function BankAccountTransaction({ dataSource, open, setOpen, uuid, width = '80%' ,style = {} }) {
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(dataSource);
    const accessToken = useAuthHeader();
    const [date, setDate] = useState([dayjs().startOf('day').add(-2, 'd'), dayjs().endOf('day')]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);


    const items =  [
        {
            name: 'date',
            placeholder: 'Tên đăng nhập',
            type: 'day'
        },
    ]

    const columns = [
        {
            title: 'Mã GD',
            dataIndex: 'transactionId',
            align: 'center',
            width: '200px',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            align: 'center',
            width: '100px',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Chuyển/Nhận',
            dataIndex: 'io',
            align: 'center',
            width: '150px',
            render: (value) => {
                if (value === 1) {
                    return <Tag color={'success'}>Nhận</Tag>
                }
                return <Tag color={'blue'}>Chuyển</Tag>
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            render: (value) => {
                return <Typography.Paragraph ellipsis={{
                    rows: 1,
                    expandable: true,
                    symbol: 'more',
                }}>{value}</Typography.Paragraph>
            }
        },
        {
            title: 'Đối tượng',
            dataIndex: 'target',
            align: 'center',
            render: (value) => {
                return <Tag color={'#55acee'}>{value}</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            width: '150px',
            render: (value) => {
                if (value === true) {
                    return <Tag color={'success'}>Thành công</Tag>
                }
                return <Tag color={'red'}>Lỗi</Tag>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'time',
            align: 'center',
            width: '200px',
            render: (value) => {
                return convertUnixTimestampToDateTime(value);
            }
        }
    ]
    // Hàm xử lý khi người dùng nhập vào ô tìm kiếm
    const handleSearch = (value) => {
        setSearchText(value);
        // Lọc dataSource dựa trên giá trị tìm kiếm
        const filtered = dataSource.filter(item =>
            Object.values(item) // Lấy tất cả các giá trị của đối tượng
                .join(' ') // Gộp các giá trị thành một chuỗi
                .toLowerCase() // Chuyển về chữ thường để tìm kiếm không phân biệt hoa thường
                .includes(value.toLowerCase()) // Kiểm tra xem có chứa giá trị tìm kiếm không
        );
        setFilteredData(filtered); // Cập nhật dữ liệu được lọc
    };

    const handleSubmitSearch = async (value) => {
        setLoading(true);
        const response = await getTransaction(accessToken,uuid, {
            transactionId: value
        });
        if (response.success) {
            setFilteredData([response.data]);
        }
        setLoading(false);
    }

    const handleChangeSearchDate = async (date, dateString) => {
        setDate(dateString);
    }

    const handleSubmitSearchDate = async () => {
        setLoading(true);
        const response = await searchTransactions(accessToken,uuid, {
            date: date,
            offset,
            limit
        });
        if (response.success) {
            setFilteredData(response.data);
        }
        setLoading(false);
    }

    const handleNextPage = async () => {
        setOffset(offset + limit);
    }

    const handlePreviousPage = async () => {
        setOffset(offset - limit);
    }

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response = await searchTransactions(accessToken,uuid, {
                date: date,
                offset,
                limit
            });
            if (response.success) {
                setFilteredData(response.data);
            }
        }
        fetchData().finally(() => setLoading(false));
    }, [offset])

    // Cập nhật filteredData khi dataSource thay đổi
    useEffect(() => {
        setFilteredData(dataSource);
    }, [dataSource]);
    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            title={'Danh sách giao dịch'}
            footer={null}
            style={style}
            width={width}
        >
            {uuid && (
                <Space
                    style={{
                        marginBottom: 30,
                        marginTop: 10
                    }}
                    direction={'horizontal'}
                >
                    <Search
                        size={'middle'}
                        placeholder={'Tìm kiếm theo mã GD'}
                        onSearch={handleSubmitSearch}
                        onChange={e => handleSearch(e.target.value)}
                        style={{
                            width: 300
                        }}
                    />
                    <RangePicker
                        onChange={handleChangeSearchDate}
                        defaultValue={[dayjs().startOf('day').add(-2, 'd'), dayjs().endOf('day').add(-1, 'd')]}
                    />
                    <Button onClick={handleSubmitSearchDate} type={'primary'} icon={<SearchOutlined/>}>Tìm kiếm</Button>
                    <Button onClick={handleNextPage}>Trang tiếp</Button>
                    <Button onClick={handlePreviousPage} disabled={offset === 0}>Trang trước</Button>
                </Space>
            )}
            <Table
                rowKey={'transactionId'}
                columns={columns}
                dataSource={filteredData}
                loading={loading}
                scroll={{
                    x: 2000,
                }}
                bordered={true}
            />
        </Modal>
    );
}

export default BankAccountTransaction;