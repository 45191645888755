export function createDeviceToken() {
    let deviceToken = localStorage.getItem("deviceToken");
    if (deviceToken == null) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 32;
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        deviceToken = result;
        localStorage.setItem("deviceToken", deviceToken);
    }
    return deviceToken;
}

export function numberFormat(number, decimals = 0, decimalSeparator = ',', thousandSeparator = '.') {
    number = parseFloat(number);
    if (!Number.isFinite(number) || isNaN(number)) {
        return 'Invalid number';
    }
    // Tạo chuỗi số thập phân
    const fixedNumber = number.toFixed(decimals);
    // Tách phần nguyên và phần thập phân
    const parts = fixedNumber.split('.');
    // Thêm dấu phân cách hàng nghìn
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

    // Nối lại phần nguyên và phần thập phân
    return parts.join(decimalSeparator);
}

export function createFileName(prefix = 'file', extension = 'txt') {
    // Lấy thời gian hiện tại và định dạng theo Ymd_His
    const now = new Date();
    const timestamp = now.getFullYear().toString() +
        String(now.getMonth() + 1).padStart(2, '0') +
        String(now.getDate()).padStart(2, '0') + '_' +
        String(now.getHours()).padStart(2, '0') +
        String(now.getMinutes()).padStart(2, '0') +
        String(now.getSeconds()).padStart(2, '0');

    // Tạo tên file với prefix và extension
    return `${prefix}_${timestamp}.${extension}`;
}

export function getBase64(img,callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
export function formatDateTime(dateTimeString, type = 'f') {
    let date = new Date(dateTimeString);
    // date.setUTCHours(date.getUTCHours() + 7);

    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear().toString();
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');
    if (type === 'd') {
        return `${day}/${month}/${year}`;
    }
    return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
}

export function convertUnixTimestampToDateTime(unixTimestamp) {
    // Tạo một đối tượng Date từ timestamp Unix (được tính bằng số giây từ Epoch)
    const date = new Date(unixTimestamp * 1000); // Vì JavaScript tính thời gian bằng số mili giây

    // Lấy các thành phần của ngày tháng
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Tháng bắt đầu từ 0, cần cộng thêm 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Trả về ngày tháng dưới dạng chuỗi
    return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function convertToInt(amount) {
    // Chuyển chuỗi thành số thực (float) sau đó thành số nguyên (int)
    return parseInt(parseFloat(amount).toFixed(0));
}