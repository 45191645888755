import axios from "../axios";

const getBankAccountBusiness = async function (accessToken, uuid) {
    try {
        const response = await axios.get('bank-account/getBankAccountBusiness', {
            headers: {
                'Authorization': accessToken
            },
            params: {
                uuid
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getBankAccountBusiness;