import {DatePicker, Form, Input, InputNumber, Select, Switch, TimePicker} from "antd";
import Amount from "../../Amount/Amount";
import dayjs from "dayjs";

function EditTableCell({editing,dataIndex,title,inputType,record,index,children,filters,...restProps}) {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                >
                    {
                        (() => {
                            if (inputType === 'switch') {
                                return <Switch defaultChecked={true} />
                            }
                            else if (inputType === 'number') {
                                return (
                                    <InputNumber
                                        style={{
                                            width: '100%'
                                        }}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                )
                            }
                            else if (inputType === 'select') {
                                return (
                                    <Select
                                        options={filters.map((item) => {
                                            return {
                                                value: item.value,
                                                label: item.text
                                            }
                                        })}
                                    />
                                );
                            }
                            else if (inputType === 'text') {
                                return (
                                    <Input/>
                                )
                            }
                            else if (inputType === 'timepicker') {
                                return (
                                    <TimePicker.RangePicker/>
                                )
                            }
                        })()
                    }
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}

export default EditTableCell;