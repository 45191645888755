import React, { useState } from 'react';
import { Button } from 'antd';
import classnames from "classnames/bind";
import styles from './ReadMoreText.module.scss';

const cx = classnames.bind(styles);
const ReadMoreText = ({ text, maxLength = 100 }) => {
    const [expanded, setExpanded] = useState(false);
    // Tách đoạn văn
    const shortText = text.slice(0, maxLength);
    const isLongText = text.length > maxLength;

    return (
        <div className={cx('wrapper')}>
            <p>
                {expanded ? text : shortText}
                {(isLongText && !expanded) && (
                    <span>... </span>
                )}
            </p>
            {isLongText && (
                <Button type="link" onClick={() => setExpanded(!expanded)}>
                    {expanded ? 'Thu gọn' : 'Tất cả'}
                </Button>
            )}
        </div>
    );
};

export default ReadMoreText;
