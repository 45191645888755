import Container from "../../components/Container/Container";
import {Button, Form, Input, InputNumber, message, Modal, Space, Table, Tag} from "antd";
import {useContext, useEffect, useState} from "react";
import getPartners from "../../api/partner/getPartners";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { formatDateTime, numberFormat} from "../../helpers/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faGlobe, faPercent, faTeletype} from "@fortawesome/free-solid-svg-icons";
import Search from "../../components/Search/Search";
import FormDrawer from "../../components/FormDrawer/FormDrawer";
import createPartner from "../../api/partner/createPartner";
import LoadingContext from "../../contexts/LoadingContext";
import TextCopy from "../../components/TextCopy";
import {EditOutlined, GoldOutlined} from "@ant-design/icons";
import getIpAddress from "../../api/partner/ip-address/getIpAddress";
import addIpAddress from "../../api/partner/ip-address/addIpAddress";
import getFees from "../../api/user/fee/getFees";
import EditTableCell from "../../components/Table/EditTableCell/EditTableCell";
import updateFee from "../../api/user/fee/updateFee";
import updateInfo from "../../api/partner/updateInfo";
import updateBalance from "../../api/partner/updateBalance";
import getInfoTelegram from "../../api/partner/getInfoTelegram";
import updateInfoTelegram from "../../api/partner/updateInfoTelegram";

function Partner() {
    const [data,setData] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const accessToken = useAuthHeader();
    const [form] = Form.useForm();
    const [formAdd] = Form.useForm();
    const [formFee] = Form.useForm();
    const [open,setOpen] = useState(false);
    const [openModelIpAddress, setOpenIpAddress] = useState(false);
    const [tableLoadingIpAddress,setTableLoadingIpAddress] = useState(false);
    const [dataSourceIpAddress,setDataSourceIpAddress] = useState([]);
    const [record,setRecord] = useState({});
    const [ipAddress,setIpAddress] = useState('');
    const [loadingBtnIpAddress,setLoadingBtnIpAddress] = useState(false);
    const [openModalFee,setOpenModalFee] = useState(false);
    const [tableLoadingFee,setTableLoadingFee] = useState(false);
    const [dataSourceFee,setDataSourceFee] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const {loading,setLoading} = useContext(LoadingContext);
    const [editingKey, setEditingKey] = useState('');
    const [editingPartner, setEditingPartner] = useState('');
    const [formPartner] = Form.useForm();
    const [formBalance] = Form.useForm();
    const [formTelegram] = Form.useForm();
    const [openModalTelegram, setOpenModalTelegram] = useState(false);
    const [summaryData, setSummaryData] = useState({
        totalBalance: 0
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [balance, setBalance] = useState(0); // Số dư ban đầu
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const isEditing = (_record) => _record.userFeeId === editingKey;

    const isEditingPartner = (_record) => _record.userId === editingPartner;

    const cancel = () => {
        setEditingKey('');
    };

    const cancelPartner = () => {
        setEditingPartner('');
    }

    const items = [
        {
            name: 'name',
            placeholder: 'Tên đối tác',
            type: 'text'
        },
        {
            name: 'username',
            placeholder: 'Tên tài khoản',
            type: 'text'
        }
    ]

    const itemAdds = [
        {
            name: 'name',
            placeholder: 'Nhập tên',
            type: 'text',
            label: 'Tên',
            required: true
        },
        {
            name: 'username',
            placeholder: 'Nhập tên tài khoản',
            type: 'text',
            label: 'Tên tài khoản',
            required: true
        },
        {
            name: 'email',
            placeholder: 'Nhập Email',
            type: 'text',
            label: 'Email',
            required: false
        },
        {
            name: 'password',
            placeholder: 'Nhập mật khẩu',
            type: 'password',
            label: 'Mật khẩu',
            required: true
        },
        {
            name: 'rePassword',
            placeholder: 'Nhập lại mật khẩu',
            type: 'password',
            label: 'Xác nhận mật khẩu',
            required: true
        }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'userId',
            sorter: true,
            align: 'center'
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            align: 'center',
            editable: true,
            inputType: 'text',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'username',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: 'center',
            editable: true,
            inputType: 'text',
            render: (value) => {
                return (value) ? <Tag>{value}</Tag> : <Tag>Trống</Tag>
            }
        },
        {
            title: 'Số dư',
            dataIndex: 'balance',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Mã bảo mật',
            dataIndex: 'signKey',
            width: '340px',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Xác thực 2 lớp',
            dataIndex: 'is2faEnabled',
            align: 'center',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Xác thực IP',
            dataIndex: 'isBlockIp',
            align: 'center',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Xác thực tiền chuyển',
            dataIndex: 'isEqualAmount',
            align: 'center',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Dùng ngân hàng riêng',
            dataIndex: 'useBankPrivate',
            align: 'center',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Callback pay in lỗi',
            dataIndex: 'isHookFailedPayIn',
            align: 'center',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Pay IN',
            dataIndex: 'payInState',
            align: 'center',
            editable: true,
            inputType: 'select',
            filters: [
                {
                    text: 'Hoạt động',
                    value: 'active'
                },
                {
                    text: 'Ngừng',
                    value: 'disabled'
                }
            ],
            render: (value) => {
                if (value === 'active') {
                    return <Tag color={'green'}>Hoạt động</Tag>
                }
                return <Tag color={'red'}>Ngừng</Tag>
            }
        },
        {
            title: 'Pay OUT',
            dataIndex: 'payOutState',
            align: 'center',
            editable: true,
            inputType: 'select',
            filters: [
                {
                    text: 'Hoạt động',
                    value: 'active'
                },
                {
                    text: 'Ngừng',
                    value: 'disabled'
                }
            ],
            render: (value) => {
                if (value === 'active') {
                    return <Tag color={'green'}>Hoạt động</Tag>
                }
                return <Tag color={'red'}>Ngừng</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'isBanned',
            align: 'center',
            editable: true,
            inputType: 'select',
            filters: [
                {
                    text: 'Hoạt động',
                    value: false
                },
                {
                    text: 'Bị chặn',
                    value: true
                }
            ],
            render: (value) => {
                if (value === false) {
                    return <Tag color={'green'}>Hoạt động</Tag>
                }
                return <Tag color={'red'}>Bị chặn</Tag>
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            render: (value) => {
                return formatDateTime(value);
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,_record) => {
                const editable = isEditingPartner(_record);
                return editable ? (
                    <Space>
                        <Button
                            type={'primary'}
                            onClick={() => savePartner(_record.userId)}
                            ghost={true}
                        >Lưu</Button>
                        <Button
                            type={'dashed'}
                            onClick={cancelPartner}
                            danger={true}
                        >Hủy</Button>
                    </Space>
                ) : (
                    <Space>
                        <Button icon={<EditOutlined/>} disabled={editingKey !== ''} onClick={() => editPartner(_record)} title={'Chỉnh sửa'}/>
                        <Button onClick={() => handleIpAddress(_record)} icon={<FontAwesomeIcon icon={faGlobe}/>} title={'Địa chỉ IP'}/>
                        <Button onClick={() => handleFee(_record)} icon={<FontAwesomeIcon icon={faPercent}/>} title={'Chiết khấu'}/>
                        <Button onClick={() => handleOpenModalBalance(_record)} icon={<GoldOutlined/>} title={'Chỉnh số dư'}/>
                        <Button onClick={() => handleOpenModalTelegram(_record)} icon={<FontAwesomeIcon icon={faTeletype} />} title={'Thêm telegram'}/>
                    </Space>
                )
            }
        }
    ];

    const handleOpenModalTelegram = async (_record) => {
        setLoading(true);
        setRecord(_record);
        const response = await getInfoTelegram(accessToken, _record.userId);
        if (response.success) {
            formTelegram.setFieldsValue(response.data);
            setOpenModalTelegram(true);
        }
        setLoading(false);
    }

    const handleUpdateInfoTelegram = async (values) => {
        const response = await updateInfoTelegram(accessToken, record.userId, values);
        if (response.success) {
            message.success('Thêm thông tin telegram thành công.')
        } else {
            message.error(response.message);
        }
    }

    const handleFee = async (_record) => {
        setOpenModalFee(true);
        await fetchFees(_record.userId);
        setRecord(_record);
    }

    const handleIpAddress = async (_record) => {
        setOpenIpAddress(true);
        await fetchIpAddress(_record.userId);
        setRecord(_record);
    }

    const fetchIpAddress = async (userId) => {
        setTableLoadingIpAddress(true);
        const response = await getIpAddress(accessToken,userId);
        if (response.success) {
            setDataSourceIpAddress(response.data);
        }
        setTableLoadingIpAddress(false);
    }

    const fetchFees = async (userId) => {
        setTableLoadingFee(true);
        const response = await getFees(accessToken,userId);
        if (response.success) {
            setDataSourceFee(response.data);
        }
        setTableLoadingFee(false);
    }

    const fetchDataPartners = async () => {
        setTableLoading(true);
        try {
            const response = await getPartners(accessToken,tableParams);
            if (response.success) {
                setData(response.data.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: response.data.total
                    },
                });
                setSummaryData({
                    totalBalance: response.data.totalBalance
                })
            }
        }
        catch (e) {}
        setTableLoading(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleSubmitAdd = (values) => {
        const fetchData = async () => {
            setLoading(true);
            const response = await createPartner(accessToken, values);
            if (response.success) {
                fetchDataPartners();
            }
            setLoading(false);
        }
        fetchData();
    }

    const handleAddIpAddress = () => {
        setLoadingBtnIpAddress(true);
        const fetchAddIpAddress = async () => {
            const response = await addIpAddress(accessToken, {
                userId: record.userId,
                ipAddress
            });
            if (response.success) {
                messageApi.success('Thêm mới địa chỉ IP thành công');
                setDataSourceIpAddress([...dataSourceIpAddress, response.data]);
            }
            else {
                messageApi.error(response.message);
            }
        }

        fetchAddIpAddress().finally(() => setLoadingBtnIpAddress(false));
    }

    const edit = (_record) => {
        formFee.setFieldsValue({
            payInFee: 0,
            payOutFee: 0,
            ..._record,
        });
        setEditingKey(_record.userFeeId);
    };

    const editPartner = (_record) => {
        formPartner.setFieldsValue({
            email: '',
            bankInFee: 0,
            bankOutFee: 0,
            ..._record
        })
        setEditingPartner(_record.userId);
    }

    const save =  async (userFeeId) => {
        setTableLoadingFee(true);
        try {
            const row = await formFee.validateFields();
            const params = {
                userFeeId,
                ...row
            };
            const response = await updateFee(accessToken, params);
            if (response.success) {
                const newData = [...dataSourceFee];
                const index = newData.findIndex((item) => userFeeId === item.userFeeId);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });
                    setDataSourceFee(newData);
                    setEditingKey('');
                } else {
                    newData.push(row);
                    setDataSourceFee(newData);
                    setEditingKey('');
                }
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
            setTableLoadingFee(false);
        }
    }

    const savePartner = async (userId)=> {
        setTableLoading(true);
        try {
            const row = await formPartner.validateFields();
            const params = {
                userId,
                ...row
            };
            const response = await updateInfo(accessToken, params);
            if (response.success) {
                updatePartner(userId, row);
                message.success('Cập nhật thông tin đối tác thành công');
            }
            else {
                message.error(response.message);
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
            setTableLoading(false);
        }
    }

    const updatePartner = (userId, row) => {
        const newData = [...data];
        const index = newData.findIndex((item) => userId === item.userId);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setData(newData);
            setEditingPartner('');
        } else {
            newData.push(row);
            setData(newData);
            setEditingPartner('');
        }
    }

    const columnFees = [
        {
            title: 'ID',
            dataIndex: 'userFeeId',
            align: 'center',
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Phí pay in (%)',
            dataIndex: 'payInFee',
            align: 'center',
            editable: true,
        },
        {
            title: 'Phí pay out (%)',
            dataIndex: 'payOutFee',
            align: 'center',
            editable: true,
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,_record) => {
                const editable = isEditing(_record);
                return editable ? (
                    <Space>
                        <Button
                            type={'primary'}
                            onClick={() => save(_record.userFeeId)}
                            ghost={true}
                        >Lưu</Button>
                        <Button
                            type={'dashed'}
                            onClick={cancel}
                            danger={true}
                        >Hủy</Button>
                    </Space>
                ) : (
                    <Button icon={<EditOutlined/>} disabled={editingKey !== ''} onClick={() => edit(_record)} type={'primary'}>Chỉnh sửa</Button>
                );
            }
        }
    ];

    const mergedColumnFees = columnFees.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (_record) => ({
                _record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(_record),
            }),
        };
    });

    const mergedColumnPartners = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (_record) => ({
                _record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditingPartner(_record),
                filters: col.filters ?? []
            }),
        };
    });

    const handleOpenModalBalance = (_record) => {
        setRecord(_record);
        setBalance(_record.balance)
        setIsModalVisible(true);
    }

    const handleSubmitBalance = async (io) => {
        setLoading(true);
        const data = formBalance.getFieldsValue();
        const response = await updateBalance(accessToken, {
            io,
            userId: record.userId,
            ...data
        });
        if (response.success) {
            updatePartner(record.userId, response.data);
            message.success('Cập nhật số dư thành công')
        }
        else {
            message.error(response.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDataPartners();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: 16,
                }}
            >
                <Button type={'primary'} ghost={true} onClick={() => setOpen(true)} icon={<FontAwesomeIcon icon={faAdd}/>}>Thêm đối tác</Button>
            </Space>
            <Search
                form={form}
                onSearch={handleSearch}
                items={items}
            />
            <Modal
                title={'Thêm thông tin bot telegram'}
                open={openModalTelegram}
                onCancel={() => setOpenModalTelegram(false)}
                width={600}
                onOk={formTelegram.submit}
            >
                <Form
                    form={formTelegram}
                    layout={'vertical'}
                    onFinish={handleUpdateInfoTelegram}
                >
                    <Form.Item
                        name={'apiTokenTelegram'}
                        label={'API Token Telegram'}
                        required={true}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={'Id chat'}
                        name={'chatIdTelegram'}
                        required={true}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Quản lý số dư"
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Space style={{ marginBottom: "16px" }}>
                    <p>Số dư hiện tại: </p>
                    <Tag>{numberFormat(balance)} VND</Tag>
                </Space>
                <Form
                    style={{ marginBottom: "16px" }}
                    layout={'vertical'}
                    form={formBalance}
                >
                    <Form.Item
                        name={'amount'}
                        label={'Số tiền:'}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            min={0}
                            style={{
                                width: '100%'
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'description'}
                        label={'Ghi chú'}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
                <Space
                    size={'middle'}
                >
                    <Button type={'primary'} onClick={() => handleSubmitBalance(1)}>Cộng</Button>
                    <Button type={'dashed'} danger={true} onClick={() => handleSubmitBalance(-1)}>Trừ</Button>
                </Space>
            </Modal>
            <Form
                form={formPartner}
                component={false}
            >
                <Table
                    rowKey={'userId'}
                    columns={mergedColumnPartners}
                    dataSource={data}
                    bordered={true}
                    scroll={{
                        x: 2400,
                    }}
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    components={{
                        body: {
                            cell: EditTableCell
                        }
                    }}
                    rowClassName={'editable-row'}
                    summary={(data) => {

                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4}>
                                    Thống kê
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align={'center'}>
                                    <Tag color={'blue'}>{numberFormat(summaryData.totalBalance)}</Tag>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}
                />
            </Form>
            <Form
                form={formAdd}
                onFinish={handleSubmitAdd}
            >
                <FormDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    items={itemAdds}
                    onSubmit={formAdd.submit}
                    title={'Thêm mới đối tác'}
                />
            </Form>
            <Modal
                title={'Danh sách địa chỉ IP'}
                open={openModelIpAddress}
                onCancel={() => setOpenIpAddress(false)}
                width={800}
                footer={null}
            >
                <Space.Compact
                    style={{
                        width: '100%',
                        margin: '20px 0'
                    }}
                >
                    <Input
                        prefix={<FontAwesomeIcon icon={faGlobe}/>}
                        placeholder={'Nhập địa chỉ IP'}
                        onChange={(e) => setIpAddress(e.target.value)}
                    />
                    <Button loading={loadingBtnIpAddress} onClick={() => handleAddIpAddress()} icon={<FontAwesomeIcon icon={faAdd}/>} type={'primary'}>Thêm</Button>
                </Space.Compact>
                <Table
                    rowKey={'uuid'}
                    columns={[
                        {
                            title: 'Địa chỉ IP',
                            dataIndex: 'ipAddress',
                            align: 'center',
                            render: (value) => {
                                return <TextCopy value={value}/>
                            }
                        },
                        {
                            title: 'Trạng thái',
                            dataIndex: 'state',
                            align: 'center',
                            render: (value) => {
                                if (value === 'active') {
                                    return <Tag color={'success'}>Hoạt động</Tag>
                                }
                                return <Tag color={'red'}>Không hoạt động</Tag>
                            }
                        },
                        {
                            title: 'Người tạo',
                            dataIndex: 'handler',
                            align: 'center',
                            render: (value) => {
                                return <Tag>{value}</Tag>
                            }
                        },
                        {
                            title: 'Thời gian thêm',
                            dataIndex: 'createdAt',
                            align: 'center',
                            render: (value) => {
                                return formatDateTime(value);
                            }
                        },
                    ]}
                    bordered={true}
                    scroll={{
                        x: 800,
                    }}
                    dataSource={dataSourceIpAddress}
                    loading={tableLoadingIpAddress}
                />
            </Modal>
            <Modal
                title={'Danh sách thông tin chiết khấu'}
                open={openModalFee}
                onCancel={() => setOpenModalFee(false)}
                width={1000}
                footer={null}
            >
                <Form
                    form={formFee}
                    component={false}
                >
                    <Table
                        rowKey={'userFeeId'}
                        columns={mergedColumnFees}
                        bordered={true}
                        scroll={{
                            x: 800,
                        }}
                        loading={tableLoadingFee}
                        dataSource={dataSourceFee}
                        components={{
                            body: {
                                cell: EditTableCell
                            }
                        }}
                    />
                </Form>
            </Modal>
        </Container>
    )
}

export default Partner;