import Form from "../../components/Auth/Form";
import Auth from "../../components/Auth/Auth";
import Input from "../../components/Auth/Form/Input";
import Button from "../../components/Auth/Form/Button";
import CheckBox from "../../components/Auth/Form/CheckBox";
import { useContext, useState } from "react";
import Error from "../../components/Auth/Error";
import LoadingContext from "../../contexts/LoadingContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import login from "../../api/auth/login";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {loginError, loginSuccess} from "../../redux/AuthSlice";
import {userUpdate} from "../../redux/UserSlice";
import {toggleSelectedKey} from "../../redux/SideBarSlice";

function Login() {
    const { setLoading }         = useContext(LoadingContext);
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [remember,setRemember] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const  {auth,device}   = useSelector(state => state);
    const signIn = useSignIn();
    const handleSubmit = async () => {
        setLoading(true);
        const response = await login({
                username: username,
                password: password,
                remember: remember,
                deviceToken: device.deviceToken
        });
        if (!response.success) {
            dispatch(loginError({
                message: response.message
            }));
            if (response.errorCode === 6001){
                localStorage.setItem('deviceId', response.data.deviceId);
                navigate('/auth/verify')
            }
        }
        else {
            const data = response.data;
            dispatch(loginSuccess(data));
            dispatch(userUpdate(data));
            signIn({
                auth: {
                    token: data.accessToken,
                    type: "Bearer"
                },
                refresh: data.accessToken,
                userState: data.userData
            });
            dispatch(toggleSelectedKey({
                defaultSelectedKey: 'dashboard',
                defaultOpenKey: null
            }))
            navigate("/");
        }
        setLoading(false);
    }

    return (
        <Auth title="Đăng nhập vào tài khoản" type={"login"} error={auth.login.error && <Error>{auth.login.errorMessage}</Error>}>
            <Form>
                <Input label={"Nhập tên tài khoản"} type={"text"} onChange={(e) => setUsername(e.target.value)} />
                <Input label={"Nhập mật khẩu"} type={"password"} onChange={(e) => setPassword(e.target.value)} />
                <CheckBox onChange={() => setRemember(!remember)}/>
                <Button onClick={handleSubmit}>Đăng nhập</Button>
            </Form>
        </Auth> 
    );
} 

export default Login;