import classnames from "classnames/bind";
import styles from "./Error.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const cx = classnames.bind(styles);

function Error({children}) {
    return (
        <div className={cx("error")}>
            <div className={cx("error-icon")}>
                <FontAwesomeIcon icon={faCircleExclamation} bounce/>
            </div>
            <div className={cx("error-message")}>
                {children}
            </div>
        </div>
    );
}

export default Error;
