import {Col, DatePicker, Form, Modal, Row, Select} from "antd";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import dayjs from "dayjs";
import {useState} from "react";
import exportFile from "../../api/exportFile";
import {createFileName} from "../../helpers/helpers";

function ExportFile({ open, setOpen, path }) {
    const accessToken = useAuthHeader();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
         setLoading(true);
         const filename = createFileName('data', 'xlsx');
         await exportFile(accessToken, values, path, filename);
         setLoading(false);
    }
    return (
        <Modal
            title={'Xuất file dữ liệu'}
            open={open}
            onCancel={() => setOpen(false)}
            width={800}
            onOk={form.submit}
            confirmLoading={loading}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
            >
                <Row
                    gutter={16}
                >
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name={'timeAt'}
                        >
                            <DatePicker.RangePicker
                                showTime={true}
                                presets={[
                                    {
                                        label: 'Hôm nay',
                                        value: [dayjs().startOf('day'), dayjs().endOf('day')],
                                    },
                                    {
                                        label: 'Hôm qua',
                                        value: [dayjs().startOf('day').add(-1, 'd'), dayjs().endOf('day').add(-1, 'd')],
                                    },
                                    {
                                        label: 'Một tuần',
                                        value: [dayjs().startOf('day').add(-7, 'd'), dayjs().endOf('day').add(-1, 'd')],
                                    },
                                    {
                                        label: 'Một tháng',
                                        value: [dayjs().startOf('day').add(-30, 'd'), dayjs().endOf('day').add(-1, 'd')],
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                            name={'timeType'}
                        >
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                placeholder={'Loại thời gian'}
                                defaultActiveFirstOption={true}
                                options={[
                                    {
                                        label: 'Thời gian tạo',
                                        value: 'created_at'
                                    },
                                    {
                                        label: 'Thời gian hoàn thành',
                                        value: 'completed_at'
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default ExportFile;