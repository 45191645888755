import axios from "../../axios";

const getStatisticDetail = async function (accessToken, username, type ,dataSearch = {}) {
    try {
        const response = await axios.post('admin/statistic/getStatisticDetail' , dataSearch,{
            headers: {
                'Authorization': accessToken
            },
            params: {
                username,
                type
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getStatisticDetail;