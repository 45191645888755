import axios from "../axios";

const getBanks = async function (accessToken, types = []) {
    try {
        const response = await axios.get('bank/getBanks', {
            headers: {
                'Authorization': accessToken
            },
            params: {
                types
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getBanks;