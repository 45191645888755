import {useContext, useEffect, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {Button, Form, Input, message, Modal, Space, Table, Tag} from "antd";
import LoadingContext from "../contexts/LoadingContext";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import TextCopy from "../components/TextCopy";
import {EditOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faGlobe} from "@fortawesome/free-solid-svg-icons";
import getIpAddress from "../api/partner/ip-address/getIpAddress";
import getPartners from "../api/partner/getPartners";
import createPartner from "../api/partner/createPartner";
import addIpAddress from "../api/partner/ip-address/addIpAddress";
import updateInfo from "../api/partner/updateInfo";
import Container from "../components/Container/Container";
import EditTableCell from "../components/Table/EditTableCell/EditTableCell";
import FormDrawer from "../components/FormDrawer/FormDrawer";
import getAdministrators from "../api/admin/administration/getAdministrators";
import createAdministrator from "../api/admin/administration/createAdministrator";

function Administration() {
    const [data,setData] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const accessToken = useAuthHeader();
    const [form] = Form.useForm();
    const [formAdd] = Form.useForm();
    const [open,setOpen] = useState(false);
    const [openModelIpAddress, setOpenIpAddress] = useState(false);
    const [tableLoadingIpAddress,setTableLoadingIpAddress] = useState(false);
    const [dataSourceIpAddress,setDataSourceIpAddress] = useState([]);
    const [record,setRecord] = useState({});
    const [ipAddress,setIpAddress] = useState('');
    const [loadingBtnIpAddress,setLoadingBtnIpAddress] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const {loading,setLoading} = useContext(LoadingContext);
    const [editingKey, setEditingKey] = useState('');
    const [editing, setEditing] = useState('');
    const [formPartner] = Form.useForm();
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const isEditing = (_record) => _record.userId === editing;

    const cancelPartner = () => {
        setEditing('');
    }


    const itemAdds = [
        {
            name: 'name',
            placeholder: 'Nhập tên',
            type: 'text',
            label: 'Tên',
            required: true
        },
        {
            name: 'username',
            placeholder: 'Nhập tên tài khoản',
            type: 'text',
            label: 'Tên tài khoản',
            required: true
        },
        {
            name: 'email',
            placeholder: 'Nhập Email',
            type: 'text',
            label: 'Email',
            required: false
        },
        {
            name: 'password',
            placeholder: 'Nhập mật khẩu',
            type: 'password',
            label: 'Mật khẩu',
            required: true
        },
        {
            name: 'rePassword',
            placeholder: 'Nhập lại mật khẩu',
            type: 'password',
            label: 'Xác nhận mật khẩu',
            required: true
        }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'userId',
            sorter: true,
            align: 'center'
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            align: 'center',
            editable: true,
            inputType: 'text',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'username',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: 'center',
            editable: true,
            inputType: 'text',
            render: (value) => {
                return (value) ? <Tag>{value}</Tag> : <Tag>Trống</Tag>
            }
        },
        {
            title: 'Xác thực 2 lớp',
            dataIndex: 'is2faEnabled',
            align: 'center',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Xác thực IP',
            dataIndex: 'isBlockIp',
            align: 'center',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'isBanned',
            align: 'center',
            editable: true,
            inputType: 'select',
            filters: [
                {
                    text: 'Hoạt động',
                    value: false
                },
                {
                    text: 'Bị chặn',
                    value: true
                }
            ],
            render: (value) => {
                if (value === false) {
                    return <Tag color={'green'}>Hoạt động</Tag>
                }
                return <Tag color={'red'}>Bị chặn</Tag>
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            render: (value) => {
                return formatDateTime(value);
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,_record) => {
                const editable = isEditing(_record);
                return editable ? (
                    <Space>
                        <Button
                            type={'primary'}
                            onClick={() => save(_record.userId)}
                            ghost={true}
                        >Lưu</Button>
                        <Button
                            type={'dashed'}
                            onClick={cancelPartner}
                            danger={true}
                        >Hủy</Button>
                    </Space>
                ) : (
                    <Space>
                        <Button icon={<EditOutlined/>} disabled={editingKey !== ''} onClick={() => edit(_record)} title={'Chỉnh sửa'}/>
                        <Button onClick={() => handleIpAddress(_record)} icon={<FontAwesomeIcon icon={faGlobe}/>} title={'Địa chỉ IP'}/>
                    </Space>
                )
            }
        }
    ];

    const handleIpAddress = async (_record) => {
        setOpenIpAddress(true);
        await fetchIpAddress(_record.userId);
        setRecord(_record);
    }

    const fetchIpAddress = async (userId) => {
        setTableLoadingIpAddress(true);
        const response = await getIpAddress(accessToken,userId);
        if (response.success) {
            setDataSourceIpAddress(response.data);
        }
        setTableLoadingIpAddress(false);
    }

    const fetchData = async () => {
        setTableLoading(true);
        try {
            const response = await getAdministrators(accessToken,tableParams);
            if (response.success) {
                setData(response.data.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: response.data.total
                    },
                });
            }
        }
        catch (e) {}
        setTableLoading(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };


    const handleSubmitAdd = async (values) => {
        const response = await createAdministrator(accessToken, values);
        if (response.success) {
            const newDataSource = [...data, response.data];
            setData(newDataSource);
        }
        setLoading(false);
    }

    const handleAddIpAddress = () => {
        setLoadingBtnIpAddress(true);
        const fetchAddIpAddress = async () => {
            const response = await addIpAddress(accessToken, {
                userId: record.userId,
                ipAddress
            });
            if (response.success) {
                messageApi.success('Thêm mới địa chỉ IP thành công');
                setDataSourceIpAddress([...dataSourceIpAddress, response.data]);
            }
            else {
                messageApi.error(response.message);
            }
        }

        fetchAddIpAddress().finally(() => setLoadingBtnIpAddress(false));
    }


    const edit = (_record) => {
        formPartner.setFieldsValue({
            email: '',
            bankInFee: 0,
            bankOutFee: 0,
            ..._record
        })
        setEditing(_record.userId);
    }

    const save = async (userId)=> {
        setTableLoading(true);
        try {
            const row = await formPartner.validateFields();
            const params = {
                userId,
                ...row
            };
            const response = await updateInfo(accessToken, params);
            if (response.success) {
                update(userId, row);
                message.success('Cập nhật thông tin đối tác thành công');
            }
            else {
                message.error(response.message);
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
            setTableLoading(false);
        }
    }

    const update = (userId, row) => {
        const newData = [...data];
        const index = newData.findIndex((item) => userId === item.userId);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setData(newData);
            setEditing('');
        } else {
            newData.push(row);
            setData(newData);
            setEditing('');
        }
    }

    const mergedColumnPartners = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (_record) => ({
                _record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(_record),
                filters: col.filters ?? []
            }),
        };
    });


    useEffect(() => {
        fetchData().finally(() => {});
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: 16,
                }}
            >
                <Button type={'primary'} ghost={true} onClick={() => setOpen(true)} icon={<FontAwesomeIcon icon={faAdd}/>}>Thêm hỗ trợ</Button>
            </Space>
            <Form
                form={formPartner}
                component={false}
            >
                <Table
                    rowKey={'userId'}
                    columns={mergedColumnPartners}
                    dataSource={data}
                    bordered={true}
                    scroll={{
                        x: 2000,
                    }}
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    components={{
                        body: {
                            cell: EditTableCell
                        }
                    }}
                    rowClassName={'editable-row'}
                />
            </Form>
            <Form
                form={formAdd}
                onFinish={handleSubmitAdd}
            >
                <FormDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    items={itemAdds}
                    onSubmit={formAdd.submit}
                    title={'Thêm mới đối tác'}
                />
            </Form>
            <Modal
                title={'Danh sách địa chỉ IP'}
                open={openModelIpAddress}
                onCancel={() => setOpenIpAddress(false)}
                width={800}
                footer={null}
            >
                <Space.Compact
                    style={{
                        width: '100%',
                        margin: '20px 0'
                    }}
                >
                    <Input
                        prefix={<FontAwesomeIcon icon={faGlobe}/>}
                        placeholder={'Nhập địa chỉ IP'}
                        onChange={(e) => setIpAddress(e.target.value)}
                    />
                    <Button loading={loadingBtnIpAddress} onClick={() => handleAddIpAddress()} icon={<FontAwesomeIcon icon={faAdd}/>} type={'primary'}>Thêm</Button>
                </Space.Compact>
                <Table
                    rowKey={'uuid'}
                    columns={[
                        {
                            title: 'Địa chỉ IP',
                            dataIndex: 'ipAddress',
                            align: 'center',
                            render: (value) => {
                                return <TextCopy value={value}/>
                            }
                        },
                        {
                            title: 'Trạng thái',
                            dataIndex: 'state',
                            align: 'center',
                            render: (value) => {
                                if (value === 'active') {
                                    return <Tag color={'success'}>Hoạt động</Tag>
                                }
                                return <Tag color={'red'}>Không hoạt động</Tag>
                            }
                        },
                        {
                            title: 'Người tạo',
                            dataIndex: 'handler',
                            align: 'center',
                            render: (value) => {
                                return <Tag>{value}</Tag>
                            }
                        },
                        {
                            title: 'Thời gian thêm',
                            dataIndex: 'createdAt',
                            align: 'center',
                            render: (value) => {
                                return formatDateTime(value);
                            }
                        },
                    ]}
                    bordered={true}
                    scroll={{
                        x: 800,
                    }}
                    dataSource={dataSourceIpAddress}
                    loading={tableLoadingIpAddress}
                />
            </Modal>
        </Container>
    )
}

export default Administration;