import { createSlice } from "@reduxjs/toolkit";
import {createDeviceToken} from "../helpers/helpers";

export const DeviceSlice = createSlice({
    name: "device",
    initialState: {
        deviceToken: createDeviceToken(),
        deviceId: null
    },
    reducers: {
        updateDevice: (state, action) => {
            state.deviceId = action.payload.deviceId;
        }
    }
});

export const {updateDevice} = DeviceSlice.actions;
export default DeviceSlice.reducer;