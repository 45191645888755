import classnames from 'classnames/bind';
import styles from './Form.module.scss';

const cx = classnames.bind(styles);

function Form(props) {
    return (
        <div className={cx('wrapper')}>
            <div className="form-group">
                {props.children}
            </div>
        </div>
    );
}

export default Form;