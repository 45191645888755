import Container from "../components/Container/Container";
import {Breadcrumb, Card, Col, Row, Statistic} from "antd";
import {Line, Pie} from "@ant-design/charts";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useContext, useEffect, useState} from "react";
import LoadingContext from "../contexts/LoadingContext";
import getStatistic from "../api/dashboard/getStatistic";
import getDataPayInToDay from "../api/dashboard/getDataPayInToDay";

function Dashboard() {
    const accessToken = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [dataChart, setDataChart] = useState([]);
    const [dataPie, setDataPie] = useState([]);

    const fetchStatistic = async () => {
        setLoading(true);
        const response = await getStatistic(accessToken);
        if (response.success) {
            setDataChart(response.data);
        }
        setLoading(false);
    }

    const fetchDataPie = async () => {
        setLoading(true);
        const response = await getDataPayInToDay(accessToken);
        if (response.success) {
            setDataPie(response.data);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchStatistic().finally();
        fetchDataPie().finally();
    }, []);

    return (
        <Container>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card
                        title={'Thống kê tổng quan'}
                        bordered={true}
                    >
                        <Line
                            data={dataChart}
                            xField={'date'}
                            yField={'totalAmount'}
                            colorField={'type'}
                            axis={{
                                x: {
                                    title: 'Ngày'
                                },
                                y: {
                                    title: 'Số tiền (VNĐ)',
                                    labelFormatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                                }
                            }}
                            style={{
                                lineWidth: 2
                            }}
                        />
                    </Card>
                </Col>
                <Col
                    span={6}
                >
                    <Card
                        title={'Thống kê IN theo đại lý'}
                        bordered={true}
                    >
                        <Pie
                            data={dataPie}
                            angleField={'totalAmount'}
                            colorField={'username'}
                            label={{
                                text: 'totalAmount'
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;