import axios from "../axios";

const updatePayIn = async function (accessToken,bankTransactionId,params = {}) {
    try {
        const response = await axios.post('/bank-transaction/updatePayIn', params , {
            headers: {
                'Authorization': accessToken
            },
            params: {
                bankTransactionId
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default updatePayIn;