import React, {useContext, useEffect, useState} from 'react';
import classnames from 'classnames/bind'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import styles from './Container.module.scss';
import SiderBar from "../SiderBar/SiderBar";
import TopMenu from "../TopMenu/TopMenu";
import loadingContext from "../../contexts/LoadingContext";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import {useNavigate} from "react-router-dom";
import {redirectAuth} from "../../routes/web";
import SiderBarProvider from "../../providers/SiderBarProvider";
import getBalance from "../../api/user/getBalance";
import {useDispatch, useSelector} from "react-redux";
import {toggleCollapsed} from "../../redux/CollapsedSlice";

const { Header,Footer, Sider, Content } = Layout;
const cx = classnames.bind(styles)
const Container = ({ children }) => {
    const {setLoading} = useContext(loadingContext);
    const accessToken  = useAuthHeader();
    const signOut = useSignOut();
    const navigate = useNavigate();
    const [balance,setBalance] = useState();
    const collapsed = useSelector(state => state.collapsed.state);
    const dispatch = useDispatch();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getBalance(accessToken);
            if (response.success) {
                setBalance(response.data.balance)
            }
            else if (response.errorCode === 401) {
                signOut();
                navigate(redirectAuth);
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    return (
        <SiderBarProvider>
            <Layout
                style={{
                    height: '100%',
                    minHeight: '100vh',
                    fontWeight: 500
                }}
                hasSider={true}
            >
                <SiderBar
                    collapsed={collapsed}
                />
                <Layout
                    style={{
                        marginLeft: collapsed ? 80 : 200,
                    }}
                >
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >
                        <div
                            className={cx('nav-wrapper')}
                        >
                            <Button
                                className={cx('btn')}
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => dispatch(toggleCollapsed())}
                                style={{
                                    width: '64px'
                                }}
                            />
                            <TopMenu
                                balance={balance}
                            />
                        </div>
                    </Header>

                    <Content
                        className={cx('content')}
                        style={{
                            borderRadius: borderRadiusLG,
                            fontWeight: 500
                        }}
                    >
                        {children}
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        Ant Design ©{new Date().getFullYear()} Created by Ant UED
                    </Footer>
                </Layout>
            </Layout>
        </SiderBarProvider>
    );
};
export default Container;