import classnames from "classnames/bind";
import styles from './Info.module.scss';

const cx = classnames.bind(styles);
function Info({children, title}) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('title')}>{title}:</div>
            <div className={cx('content')}>{children ?? 'undefined'}</div>
        </div>
    )
}
export default Info;