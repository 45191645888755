import classnames from "classnames/bind";
import styles from './ItemDropDown.module.scss'
import {Link} from "react-router-dom";

const cx = classnames.bind(styles);
function ItemDropDown({ icon, children, onClick, to }) {
    return to ? (
        <Link
            to={to}
            className={cx('wrapper')}
            style={{
                color: 'blue'
            }}
        >
            <div className={cx('icon')}>
                {icon}
            </div>
            <div
                className={cx('item')}
            >{children}</div>
        </Link>
    ) : (
        <div
            className={cx('wrapper')}
            onClick={onClick}
        >
            <div className={cx('icon')}>
                {icon}
            </div>
            <div
                className={cx('item')}
            >{children}</div>
        </div>
    );
}

export default ItemDropDown;