import {Menu} from "antd";
import {DashboardOutlined, UploadOutlined, VideoCameraOutlined} from "@ant-design/icons";
import React, {useContext, useState} from "react";
import {Layout} from "antd";
import classnames from "classnames/bind";
import styles from './SiderBar.module.scss'
import {appRoutes} from "../../routes/web";
import {Link} from "react-router-dom";
import SiderBarContext from "../../contexts/SiderBarContext";
import LabelMenu from "./LabelMenu/LabelMenu";
import IconItem from "./ItemIcon/ItemIcon";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Statistic from "../../pages/Statistic";
import {useDispatch, useSelector} from "react-redux";
import {toggleOpenKey, toggleSelectedKey} from "../../redux/SideBarSlice";
const {Sider}= Layout;
const cx = classnames.bind(styles);

function SiderBar({collapsed}) {
    const userData = useAuthUser();
    const sider = useSelector(state => state.sider);
    const dispatch = useDispatch();

    const setKeyActive = (key, openKey = null) => {
        if (openKey) {
            dispatch(toggleOpenKey({
                defaultOpenKey: key
            }));
            dispatch(toggleSelectedKey({
                defaultSelectedKey: openKey
            }));
        }
        else {
            dispatch(toggleSelectedKey({
                defaultSelectedKey: key
            }));
            dispatch(toggleOpenKey({
                defaultOpenKey: null
            }));
        }
    }

    return (
        <Sider
            trigger={null}
            collapsible={true}
            collapsed={collapsed}
            breakpoint={'lg'}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div className={cx('logo-vertical')} />
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[sider.defaultSelectedKey]}
                defaultOpenKeys={[sider.defaultOpenKey]}
                items={appRoutes.map((value, index) => {
                    if (value.roles.includes(userData.role)) {
                        if (value.type === 'item') {
                            return {
                                key: value.key,
                                label: (
                                    <LabelMenu>
                                        <Link
                                            to={value.path}
                                            onClick={() => setKeyActive(value.key)}
                                        >
                                            {value.label}
                                        </Link>
                                    </LabelMenu>
                                ),
                                icon: <IconItem>{value.icon}</IconItem>
                            }
                        }
                        else if (value.type === 'children') {
                            return {
                                key:   value.key,
                                label: (
                                    <LabelMenu>{value.label}</LabelMenu>
                                ),
                                icon:  <IconItem>{value.icon}</IconItem>,
                                children: value.children.map((item, indexChildren) => {
                                    return {
                                        ...item,
                                        label: (
                                            <Link
                                                key={index + '-' +indexChildren}
                                                to={item.path}
                                                onClick={() => setKeyActive(value.key, item.key)}
                                            >
                                                {item.label}
                                            </Link>
                                        )
                                    }
                                })
                            }
                        }
                        return {
                            type: value.type,
                            label: value.label
                        }
                    }
                })}
            />
        </Sider>
    )
}

export default SiderBar;