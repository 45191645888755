import classnames from "classnames/bind";
import styles from './LabelMenu.module.scss';

const cx = classnames.bind(styles);
function LabelMenu({children, onClick}) {
    return (
        <div onClick={onClick} className={cx('wrapper')}>{children}</div>
    )
}

export default LabelMenu;