import axios from "../../axios";

const addIpAddress = async function (accessToken,data = {}) {
    try {
        const response = await axios.post('partner/ip-address/addIpAddress', data ,{
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default addIpAddress;