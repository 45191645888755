import axios from "../../axios";

const getBankAccountDevice = async function (accessToken, id) {
    try {
        const response = await axios.get('/bank-account/device/getBankAccountDevice', {
            headers: {
                'Authorization': accessToken
            },
            params: {
                id
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getBankAccountDevice;