import axios from "../../axios";

const getStatistics = async function (accessToken, type, params = {}) {
    try {
        const response = await axios.post('admin/statistic/getStatistics', params ,{
            headers: {
                'Authorization': accessToken
            },
            params: {
                type
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getStatistics;