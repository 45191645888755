import axios from "../../axios";

const getImageQrCode = async function (accessToken,id = {}) {
    try {
        const response = await axios.get('admin/withdraw/getImageQrCode',{
            headers: {
                'Authorization': accessToken
            },
            params: {
                id
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getImageQrCode;