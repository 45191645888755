import axios from "../../axios";

const getListTransaction = async function (accessToken,uuid = {}) {
    try {
        const response = await axios.get('admin/pay-in/getListTransaction',{
            headers: {
                'Authorization': accessToken
            },
            params: {
                uuid
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getListTransaction;