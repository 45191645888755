import classnames from 'classnames/bind';
import styles from './Auth.module.scss';
import logo from '../../assets/logo.png';

const cx = classnames.bind(styles);

function Auth ({children,error,title = 'Đăng nhập'}) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('content')}>
                <div className={cx('auth-header')}>
                    <img className={cx('logo')} src={logo} alt="Logo" />
                    <div className={cx('title')}>
                        <h3>{title}</h3>
                    </div>
                    {error}
                </div>
                <div className={cx('auth-body')}>
                    {children}
                </div>
                <div className={cx('auth-footer')}>
                    <div className={cx('action')}>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Auth;