import Container from "../../components/Container/Container";
import {Button, Flex, Form, message, Modal, Popconfirm, QRCode, Space, Table, Tag, Typography} from "antd";
import {useContext, useEffect, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import LoadingContext from "../../contexts/LoadingContext";
import {formatDateTime, numberFormat} from "../../helpers/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextCopy from "../../components/TextCopy";
import getWithdrawals from "../../api/admin/withdraw/getWithdrawals";
import FormDrawer from "../../components/FormDrawer/FormDrawer";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import getBankWithdraw from "../../api/withdraw-account/getBankWithdraw";
import getWithdrawAccounts from "../../api/withdraw-account/getWithdrawAccounts";
import createWithdrawAccount from "../../api/withdraw-account/createWithdrawAccount";
import {EditOutlined} from "@ant-design/icons";
import updateBank from "../../api/bank/updateBank";
import EditTableCell from "../../components/Table/EditTableCell/EditTableCell";
import updateWithdrawAccount from "../../api/withdraw-account/updateWithdrawAccount";
import dayjs from "dayjs";

function Withdraw() {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const accessToken = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [banks,setBanks] = useState([]);
    const [open,setOpen] = useState(false);
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [formEdit] = Form.useForm();
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const isEditing = (record) => record.id === editingKey;

    const cancel = () => {
        setEditingKey('');
    };

    const item = [
        {
            name: 'accountNumber',
            placeholder: 'Nhập số tài khoản',
            type: 'text',
            label: 'Số tài khoản',
            required: true
        },
        {
            name: 'accountName',
            placeholder: 'Nhập tên tài khoản',
            type: 'text',
            label: 'Tên tài khoản',
            required: true
        },
        {
            name: 'bankCode',
            placeholder: 'Chọn ngân hàng',
            type: 'select',
            label: 'Ngân hàng nhận',
            required: true,
            options: (banks || []).map((item) => {
                return {
                    value:  item.bankCode,
                    label: `${item.bankName} - ${item.displayName} (${item.shortName})`
                }
            })
        },
        {
            name: 'state',
            placeholder: 'Chọn trạng thái',
            type: 'select',
            label: 'Trạng thái',
            options: [
                {
                    value: 'active',
                    label: 'Bật'
                },
                {
                    value: 'disabled',
                    label: 'Tắt'
                }
            ]
        }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center',
            sorter: true
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center'
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            align: 'center',
            editable: true,
            inputType: 'text',
            render: (value) => {
                return (
                    <TextCopy value={value}/>
                )
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'accountName',
            align: 'center',
            editable: true,
            inputType: 'text',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'state',
            align: 'center',
            editable: true,
            inputType: 'select',
            filters: [
                {
                    text: 'Bật',
                    value: 'active'
                },
                {
                    text: 'Tắt',
                    value: 'disabled'
                }
            ],
            render: (value) => {
                if (value === 'active') {
                    return <Tag color={'green'}>Bật</Tag>
                }
                return <Tag color={'red'}>Tắt</Tag>
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            render: (value) => {
                return formatDateTime(value)
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Button
                            type={'primary'}
                            onClick={() => save(record.id)}
                            ghost={true}
                        >Lưu</Button>
                        <Button
                            type={'dashed'}
                            onClick={cancel}
                            danger={true}
                        >Hủy</Button>
                    </Space>
                ) : (
                    <Button icon={<EditOutlined/>} disabled={editingKey !== ''} onClick={() => edit(record)} type={'primary'}>Chỉnh sửa</Button>
                );
            }
        }
    ];

    const edit = (record) => {
        formEdit.setFieldsValue({
            state: 'active',
            accountName: '',
            accountNumber: '',
            ...record,
        });
        setEditingKey(record.id);
    };

    const save =  async (id) => {
        setTableLoading(true);
        try {
            const row = await formEdit.validateFields();
            const params = {
                id,
                ...row
            };
            const response = await updateWithdrawAccount(accessToken, id, params);
            if (response.success) {
                const newData = [...dataSource];
                const index = newData.findIndex((item) => id === item.id);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });
                    setDataSource(newData);
                    setEditingKey('');
                } else {
                    newData.push(row);
                    setDataSource(newData);
                    setEditingKey('');
                }
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
            setTableLoading(false);
        }
    }

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getWithdrawAccounts(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleSubmit = async (values) => {
        setLoading(true);
        const response = await createWithdrawAccount(accessToken, values);
        if (response.success) {
            fetchDataTable().finally(() => {
                message.success('Thêm tài khoản nhận tiền tự động thành công');
            });
        } else {
            message.error(response.message);
        }
        setLoading(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                filters: col.filters ?? []
            }),
        };
    });

    useEffect(() => {
        fetchDataTable().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response = await getBankWithdraw(accessToken);
            if (response.success) {
                setBanks(response.data);
            }
        }
        fetchData().finally(() => setLoading(false));
    }, []);

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: 16,
                }}
            >
                <Button type={'primary'} ghost={true} onClick={() => setOpen(true)} icon={<FontAwesomeIcon icon={faAdd}/>}>Thêm tài khoản</Button>
            </Space>
            <Form
                form={formEdit}
                component={false}
            >
                <Table
                    rowKey={'id'}
                    columns={mergedColumns}
                    dataSource={dataSource}
                    bordered={true}
                    scroll={{
                        x: 1800,
                    }}
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    components={{
                        body: {
                            cell: EditTableCell
                        }
                    }}
                />
            </Form>
            <Form
                form={form}
                onFinish={handleSubmit}
            >
                <FormDrawer
                    open={open}
                    onClose={() => setOpen(false)}
                    items={item}
                    onSubmit={form.submit}
                    title={'Thêm tài khoản nhận tiền'}
                />
            </Form>
        </Container>
    )
}

export default Withdraw;