import classnames from "classnames/bind";
import styles from "./Input.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const cx = classnames.bind(styles);

function Input(props) {
    const [fontAweSome, setFontAweSome] = useState(faEye);
    const { type, label } = props;
    const [typeInput, setTypeInput] = useState(type);
    const handleHidden = () => {
        if (fontAweSome === faEye) {
            setTypeInput("text");
            setFontAweSome(faEyeSlash);
        } else {
            setTypeInput("password");
            setFontAweSome(faEye);
        }
    };
    return (
        <div className={cx("form-control")}>
            <input className={cx("input")} {...props} type={typeInput} required />
            <label className={cx("label")}>{label}</label>
            {type === "password" ? (
                <span className={cx("icon")}>
                <FontAwesomeIcon icon={fontAweSome} onClick={handleHidden} />
                </span>
            ) : null}
        </div>
    );
}

export default Input;
