import classnames from "classnames/bind";
import styles from './GoogleAuthenticate.module.scss';
import {GoogleOutlined, SecurityScanOutlined} from "@ant-design/icons";
import {Button, Flex, Form, Input, message, Modal, QRCode} from "antd";
import {useContext, useEffect, useState} from "react";
import getSecurity from "../../api/user/getSecurity";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import LoadingContext from "../../contexts/LoadingContext";
import getGoogleSecretKey from "../../api/user/getGoogleSecretKey";
import verifyGoogleSecret from "../../api/user/verifyGoogleSecret";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import {useNavigate} from "react-router-dom";
import {redirectAuth} from "../../routes/web";
import cancelGoogleSecret from "../../api/user/cancelGoogleSecret";

const cx = classnames.bind(styles);
function GoogleAuthenticate() {
    const [open,setOpen] = useState(false);
    const [faEnabled,setFaEnabled]= useState(false);
    const [qrCode,setQrcode] = useState(null);
    const [statusQrcode,setStatusQrcode] = useState('loading');
    const [googleSecretKey,setGoogleSecretKey]  = useState(null);
    const accessToken = useAuthHeader();
    const {loading,setLoading} = useContext(LoadingContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [otp,setOtp] = useState(null);
    const signOut = useSignOut();
    const navigate = useNavigate();


    const handleModal = (state) => {
        setOpen(state);
        if (state === true && !faEnabled) {
            const fetchData = async () => {
                setStatusQrcode('loading');
                const response = await getGoogleSecretKey(accessToken);
                if (response.success) {
                    setQrcode(response.data.qrCode);
                    setGoogleSecretKey(response.data.google2faSecret);
                }
                setStatusQrcode('active');
            }
            fetchData();
        }
    }

    const handleVerify = () => {
        if (otp.length !== 6) {
            messageApi.open({
                type: 'error',
                content: 'Mã OTP không hợp lệ',
            });
            return;
        }
        const fetchData = async () => {
            setLoading(true);
            const response = await verifyGoogleSecret(accessToken, otp);
            if (response.success || response.errorCode === 401) {
                setTimeout(() => {
                    signOut();
                    navigate(redirectAuth);
                }, 1000);
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: response.message,
                });
            }
            setLoading(false);
        }
        fetchData();
    }

    const handleCancelGoogleSecret = () => {
        const fetchData = async () => {
            setLoading(true);
            const response = await cancelGoogleSecret(accessToken);
            if (response.success) {
                setOpen(false);
                setFaEnabled(false);
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: response.message,
                });
            }
            setLoading(false);
        }
        fetchData();
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getSecurity(accessToken);
            if (response.success) {
                setFaEnabled(response.data.is2faEnabled);
            }
            else if (response.errorCode === 401){
                signOut();
                navigate(redirectAuth);
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    return (
        <>
            {contextHolder}
            <div className={cx('wrapper')}>
                <div className={cx('icon')}>
                    <GoogleOutlined/>
                </div>
                <div className={cx('body')}>
                    <div className={cx('title')}>Bảo mật bằng GoogleAuth</div>
                    <div className={cx('content')}>Mã xác thực sẽ hiện trên App điện thoại của bạn (Mức an toàn cao)</div>
                </div>
                <div className={cx('btn')}>
                    <Button type={'primary'} danger={faEnabled} ghost={true} onClick={() => handleModal(true)}>{faEnabled ? 'Hủy' : 'Cài đặt'}</Button>
                </div>
            </div>
            <Modal
                title={'Cài đặt bảo mật 2FA'}
                open={open && !faEnabled}
                onCancel={() => handleModal(false)}
                onOk={handleVerify}
                cancelText={'Hủy'}
            >
                <Flex
                    justify={'center'}
                    style={{
                        marginBottom: 20
                    }}
                >
                    <QRCode value={qrCode} status={statusQrcode}/>
                </Flex>
                <Form>
                    <Form.Item>
                        <Input
                            addonBefore={googleSecretKey}
                            addonAfter={<SecurityScanOutlined/>}
                            placeholder={'Nhập mã trên app sau khi quét'}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Bạn có chắc chắn muốn hủy bước bảo mật GoogleAuth'}
                open={open && faEnabled}
                cancelText={'Hủy'}
                onCancel={() => handleModal(false)}
                onOk={handleCancelGoogleSecret}
            >

            </Modal>
        </>
    )
}

export default GoogleAuthenticate;