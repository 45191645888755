import Container from "../../components/Container/Container";
import {Button, Card, Col, Flex, Form, Input, message, Row, Tooltip, Typography} from "antd";
import {CopyOutlined, GoogleOutlined} from "@ant-design/icons";
import GoogleAuthenticate from "../../components/GoogleAuthenticate/GoogleAuthenticate";
import {useContext, useEffect, useState} from "react";
import updatePassword from "../../api/user/updatePassword";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import LoadingContext from "../../contexts/LoadingContext";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import getApiToken from "../../api/user/getApiToken";

function Security() {
    const [password,setPassword] = useState();
    const [newPassword,setNewPassword] = useState();
    const [reNewPassword,setReNewPassword] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const accessToken = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [apiToken,setApiToken] = useState('');

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(apiToken);
        messageApi.success('API Token đã được sao chép!');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleUpdate = () => {
        if (!password) {
            messageApi.open({
                type: 'error',
                content: 'Vui lòng nhập mật khẩu hiện tại',
            });
            return;
        }
        if (!newPassword) {
            messageApi.open({
                type: 'error',
                content: 'Vui lòng nhập mật khẩu mới',
            });
            return;
        }
        if (!reNewPassword) {
            messageApi.open({
                type: 'error',
                content: 'Vui lòng xác nhận mật khẩu mới',
            });
            return;
        }
        if (newPassword !== reNewPassword) {
            messageApi.open({
                type: 'warning',
                content: 'Mật khẩu mới và mật khẩu xác nhận không trùng khớp vui lòng kiểm tra lại',
            });
            return;
        }
        const fetchData = async () => {
            setLoading(true);
            const response = await updatePassword(accessToken,password,newPassword);
            if (!response.success) {
                messageApi.open({
                    type: 'error',
                    content: response.message
                });
            }
            else {
                messageApi.open({
                    type: 'success',
                    content: 'Cập nhật mật khẩu thành công'
                });
            }
            setLoading(false);
        }
        fetchData();
    }

    useEffect(() => {
        setLoading(true);
        const fetchApiToken = async () => {
            const response = await getApiToken(accessToken);
            if (response.success) {
                setApiToken(response.data.apiToken);
            }
        }

        fetchApiToken().finally(() => setLoading(false));
    }, []);

    return (
        <Container>
            {contextHolder}
            <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Card title={'Đổi mật khẩu'}>
                        <Form
                            layout={'horizontal'}
                            labelCol={{
                                span: 6
                            }}
                        >
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label={'Mật khẩu hiện tại'} name={'password'}
                                           style={{
                                               fontWeight: 600
                                           }}
                                >
                                    <Input.Password
                                        placeholder={'Nhập mật khẩu hiện tại'}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label={'Mật khẩu mới'} name={'newPassword'}
                                           style={{
                                               fontWeight: 600
                                           }}
                                >
                                    <Input.Password
                                        placeholder={'Nhập mật khẩu mới'}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label={'Xác nhận mật khẩu mới'} name={'reNewPassword'}
                                           style={{
                                               fontWeight: 600
                                           }}
                                >
                                    <Input.Password
                                        placeholder={'Nhập lại mật khẩu mới'}
                                        onChange={(e) => setReNewPassword(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Flex justify={'center'}>
                                    <Button type={'primary'} onClick={handleUpdate}>Xác nhận</Button>
                                </Flex>
                            </Col>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Card title={'Cài đặt bảo mật'}>
                        <GoogleAuthenticate/>
                        <Tooltip
                            title={copied ? "Đã sao chép!" : "Sao chép API Token"}
                        >
                            <Input
                                style={{
                                    marginTop: '30px',
                                    height: '34px'
                                }}
                                value={apiToken}
                                readOnly
                                addonAfter={
                                    <Button type="text" icon={<CopyOutlined />} onClick={handleCopy}>
                                        Sao chép
                                    </Button>
                                }
                                size={'large'}
                                addonBefore={'API Key'}
                            />
                        </Tooltip>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Security;