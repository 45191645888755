import {Tag, Typography} from "antd";

function TextCopy({value}) {
    return (
        <Typography.Paragraph
            copyable={{
                text: value
            }}
            style={{
                margin: 0
            }}
        ><Tag>{value}</Tag></Typography.Paragraph>
    )
}

export default TextCopy;