import axios from "../axios";

const transfer = async function (accessToken,uuid, data) {
    try {
        const response = await axios.post('/bank-account/transfer',data, {
            headers: {
                'Authorization': accessToken
            },
            params: {
                uuid
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default transfer;