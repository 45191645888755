import axios from "../axios";

const login = async (data) => {
    try {
        const response = await axios.post('auth/login', data, {
            headers: {
                'Authorization': `Bearer undefined`
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status,
            message: e.message
        }
    }
}

export default login;
